<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5">  Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS INQUIETUDES ANTISOBORNO:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-hand-paper fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaInquietudesAntisoborno.length}}</span>
                            <br>
                            <span class="text-muted">Inquietudes antisoborno registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de inquietudes antisoborno</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('017-37001-IQA','c') == 1" cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalNuevaInquietud = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nueva inquietud</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5">Inquietudes antisoborno registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaInquietudesAntisoborno" :fields="cabecera" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(internoExterno)="param">
                                    <span v-if="param.item.internoExterno==1">Interno</span>
                                    <span v-else-if="param.item.internoExterno==2">Externo</span>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('017-37001-IQA','u') == 1" @click="abrirModalActualizarInquietud(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('017-37001-IQA','d') == 1" @click="eliminarInquietud(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(estadoAtencion)="param">
                                    {{listaEstadoAtencion.find(x => x.value==param.item.estadoAtencion).text}}
                                </template>
                                <template #cell(puestoPersona)="param">
                                    {{comboPuestos.find(x => x.idPuestoTrabajo==param.item.puestoPersona).puestoTrabajo}}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal size="lg" color="primario" :show.sync="modalNuevaInquietud">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo control antisoborno</span>
            </h6>
            <CButtonClose @click="modalNuevaInquietud = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(nuevaInquietudAntisoborno)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="número de inquietud" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de inquietud:" class="mb-2">
                                <b-form-input v-model="datosInquietud.numeroInquietud" :state="getValidationState(validationContext)" placeholder="Ingrese el número de inquietud"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input v-model="datosInquietud.nombre" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="persona" v-slot="validationContext">
                            <b-form-group label="Persona:" class="mb-2">
                                <b-form-input v-model="datosInquietud.persona" :state="getValidationState(validationContext)" placeholder="Ingrese la persona"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="puesto de la persona">
                            <b-form-group label="Puesto de la persona:" class="mb-2">
                                <v-select :reduce="comboPuestos =>comboPuestos.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model.lazy="datosInquietud.puestoPersona" :options="comboPuestos">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="estado de atención" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Estado de atención:" class="mb-2">
                                <b-form-select v-model="datosInquietud.estadoAtencion" :options="listaEstadoAtencion" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="">
                            <b-form-group label="" class="mb-2">
                                <b-form-radio-group class="mt-4" plain v-model="datosInquietud.internoExterno" :options="[{value:1,text:'Interno'},{value:2,text:'Externo'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" v-model="datosInquietud.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalNuevaInquietud = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal size="lg" color="primario" :show.sync="modalActualizarInquietud">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar control antisoborno </span>
            </h6>
            <CButtonClose @click="modalActualizarInquietud = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarInquietudAntisoborno)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="número de inquietud" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de inquietud:" class="mb-2">
                                <b-form-input v-model="datosInquietudActualizar.numeroInquietud" :state="getValidationState(validationContext)" placeholder="Ingrese el número de inquietud"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input v-model="datosInquietudActualizar.nombre" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="persona" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Persona:" class="mb-2">
                                <b-form-input v-model="datosInquietudActualizar.persona" :state="getValidationState(validationContext)" placeholder="Ingrese la persona"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="puesto de la persona">
                            <b-form-group label="Puesto de la persona:" class="mb-2">
                                <v-select :reduce="comboPuestos =>comboPuestos.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model.lazy="datosInquietudActualizar.puestoPersona" :options="comboPuestos">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="estado de atención" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Estado de atención:" class="mb-2">
                                <b-form-select v-model="datosInquietudActualizar.estadoAtencion" :options="listaEstadoAtencion" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="">
                            <b-form-group label="" class="mb-2">
                                <b-form-radio-group class="mt-4" plain v-model="datosInquietudActualizar.internoExterno" :options="[{value:1,text:'Interno'},{value:2,text:'Externo'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" v-model="datosInquietudActualizar.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarInquietud = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalNuevaInquietud: false,
            modalActualizarInquietud: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "numeroInquietud",
                    label: "Número de inquietud",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "persona",
                    label: "Persona",
                    class: "text-center",
                },
                {
                    key: "puestoPersona",
                    label: "Puesto persona",
                    class: "text-center",
                },
                {
                    key: "estadoAtencion",
                    label: "Estado de atención",
                    class: "text-center",
                },
                {
                    key: "internoExterno",
                    label: "Interno/Externo",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            campoPersonas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "control",
                    label: "Control",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: '',
                    class: "text-center"
                }
            ],
            listaInquietudesAntisoborno: [],
            datosInquietud: {
                idCliente: '',
                numeroInquietud: '',
                nombre: '',
                persona: '',
                puestoPersona: '',
                estadoAtencion: null,
                internoExterno: 1,
                descripcion: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            datosInquietudActualizar: {
                idInquietud: '',
                idCliente: '',
                numeroInquietud: '',
                nombre: '',
                persona: '',
                puestoPersona: '',
                estadoAtencion: null,
                internoExterno: 1,
                descripcion: '',
                ejercicio: '',
            },
            disabled: false,
            listaEstadoAtencion: [
                {
                    value: 1,
                    text: 'Propuesta'
                },
                {
                    value: 2,
                    text: 'Resuelta'
                },
                {
                    value: 3,
                    text: 'En proceso'
                },
                {
                    value: 4,
                    text: 'Reasignar a investigación'
                },
            ],
            comboPuestos: [],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosInquietud.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboPuestos = response.data;
                    me.listarInquietudesAntisoborno();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarInquietudesAntisoborno() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-inquietudes-antisoborno-sga", {
                        params: {
                            idCliente: me.datosInquietud.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    me.listaInquietudesAntisoborno = response.data
                    me.totalRows = me.listaInquietudesAntisoborno.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        nuevaInquietudAntisoborno() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-inquietud-antisoborno-sga",
                    me.datosInquietud, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarInquietudesAntisoborno();
                    me.modalNuevaInquietud = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarInquietudAntisoborno() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-inquietud-antisoborno-sga",
                    me.datosInquietudActualizar, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarInquietudesAntisoborno();
                    me.modalActualizarInquietud = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarInquietud(param) {
            let me = this;

            me.datosInquietudActualizar.idInquietud = param.item.idInquietud;
            me.datosInquietudActualizar.idCliente = param.item.idCliente;
            me.datosInquietudActualizar.numeroInquietud = param.item.numeroInquietud;
            me.datosInquietudActualizar.nombre = param.item.nombre;
            me.datosInquietudActualizar.persona = param.item.persona;
            me.datosInquietudActualizar.puestoPersona = param.item.puestoPersona;
            me.datosInquietudActualizar.internoExterno = param.item.internoExterno;
            me.datosInquietudActualizar.estadoAtencion = param.item.estadoAtencion;
            me.datosInquietudActualizar.descripcion = param.item.descripcion;
            me.datosInquietudActualizar.ejercicio = param.item.ejercicio;

            me.modalActualizarInquietud = true;
        },
        resetModalNuevaInquietud() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosInquietud.numeroInquietud = '';
            this.datosInquietud.nombre = '';
            this.datosInquietud.persona = '';
            this.datosInquietud.internoExterno = 1;
            this.datosInquietud.estadoAtencion = null;
            this.datosInquietud.descripcion = '';
        },
        resetModalActualizarInquietud() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        eliminarInquietud(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la inquietud?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-inquietud-antisoborno-sga", {
                            idInquietud: param.item.idInquietud,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarInquietudesAntisoborno();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalNuevaInquietud: function (val) {
            if (val == false) {
                this.resetModalNuevaInquietud();
            }
        },
        modalActualizarInquietud: function (val) {
            if (val == false) {
                this.resetModalActualizarInquietud();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosInquietud.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
            
        }

    }

}
</script>
